*,
*:before,
*:after {
  box-sizing: border-box;
}

h3 {
  font-weight: 900 !important;
}

.steps {
  max-width: 300px;
  margin: 0 auto;
}

.step {
  display: flex;
  position: relative;
}

.step:after {
  content: "";
  position: absolute;
  left: 15px;
  top: 32px;
  height: 0;
  width: 2px;
  background-color: #198efd; 
}

.spacer {
  margin-bottom: 16px;
}

.step .info {
  margin: 4px 10px 0px;
}

.step .title {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 8px;
}

.step .text {
  font-size: 12px;
  padding-bottom: 0;
  margin: 0;
}

.step:not(:last-child):after {
  height: 100%;
}

.number {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid #198efd; 
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  margin-right: 14px;
  background-color: #fff;
}

.MuiBox-root-9 {
  max-width: 100% !important;
}
